<template>
  <div class="predefined-filter-params">
    <b-modal
      ref="preview_predefined_filter_params"
      v-model="show_preview_params_modal"
      title="References"
      title-tag="h5"
      size="xl"
      centered
      hide-footer
      lazy
    >
      <!--
      no-close-on-backdrop
      header-bg-variant="dark"
      header-text-variant="light"
      -->
      <div>
        <div
          class="param-item-container mb-3"
          v-for="(param_item, item_index) in references"
          :key="param_item.id"
        >
          <div class="flex align-items-center">
            <h6 class="mb-0">
              {{ `${(item_index + 1)}: ${param_item['title']}` }}
            </h6>
            <div class="ml-2 flex align-items-center">
              <button
                v-if="copied != param_item.id"
                class="btn btn-sm btn-success py-half"
                @click="onCopyPredefinedFilter({ param_item })"
              >
                <i class="far fa-copy"></i>
              </button>
              <div v-else class="bg-orange px-2 bg-light">Copied!</div>
            </div>
          </div>
          <VJsoneditor v-model="param_item['params']" class="" :options="{ mode: 'preview' }" />
        </div>
      </div>
    </b-modal>
    <button
      class="btn btn-sm btn-info ml-2"
      @click="() => { this.show_preview_params_modal = true; }"
    >
      See Params References
    </button>
  </div>
</template>

<script>
import VJsoneditor from 'v-jsoneditor';
import moment from 'moment';

export default {
  components: {
    VJsoneditor,
  },
  props: {

  },
  data: () => {
    const today = new Date();
    const lastDayOfThisMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    return {
      copied: null,
      show_preview_params_modal: false,
      references: [
        {
          id: 'invoice-date-matching-end-of-current-month',
          title: 'Invoice\'s invoice_date matching end of current month',
          params: {
            "with_properties": [],
            "filters": [
              {
                filter_type: "property",
                existence: "present",
                property_key: "invoice_date",
                equal: moment(lastDayOfThisMonth).format('YYYY-MM-DD'),
              },
            ],
          },
        },
        {
          id: 'matching-id',
          title: 'Invoice matching ID',
          params: {
            "with_properties": [],
            "filters": [
              {
                filter_type: "property",
                existence: "present",
                property_key: "id",
                equal: 13,
              },
            ],
          },
        },
        {
          id: 'with-order-with-replenishment',
          title: 'Invoice with replenishment order',
          params: {
            with_properties: [],
            filters: [
              {
                operator: 'or',
                filter_type: 'expression',
                filters: [
                  {
                    filter_type: 'relationship',
                    existence: 'present',
                    from_class_name: 'Invoice',
                    relationship_name: 'IncludeOrders',
                    filters: [
                      {
                        filter_type: 'relationship',
                        existence: 'present',
                        from_class_name: 'Order',
                        relationship_name: 'ForReplenishmentOrderDate',
                        filters: []
                      }
                    ]
                  },
                  {
                    filter_type: 'relationship',
                    existence: 'present',
                    from_class_name: 'Invoice',
                    relationship_name: 'IncludeDeliveryOrders',
                    filters: [
                      {
                        filter_type: 'relationship',
                        existence: 'present',
                        from_class_name: 'DeliveryOrder',
                        relationship_name: 'IncludeOrders',
                        filters: [
                          {
                            filter_type: 'relationship',
                            existence: 'present',
                            from_class_name: 'Order',
                            relationship_name: 'ForReplenishmentOrderDate',
                            filters: []
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
        },
      ],
    };
  },
  computed: {

  },
  created() {

  },
  methods: {
    onCopyPredefinedFilter({ param_item }) {
      this.copied = param_item.id;
      if (this.timeoutEvent) {
        clearTimeout(this.timeoutEvent);
      }
      this.timeoutEvent = setTimeout(() => {
        this.copied = null;
      }, 2000);

      navigator.clipboard.writeText(JSON.stringify(param_item.params));
    },
  },
};
</script>

<style lang="scss" scoped>
.predefined-filter-params {

}
</style>
